import React, { FC } from 'react';
import './StickyTests.css';

interface TestsPageGridProps {
  children: React.ReactNode;
}

const TestsPageGrid: FC<TestsPageGridProps> = ({ children }) => {
  return (
    <div className="tests-page-grid">
      {children}
    </div>
  );
};

export default TestsPageGrid;
