import React from 'react'
import ExtensionInstallation from './ExtensionInstallation'

export const Extension = () => {
  return (
    <div>
      <ExtensionInstallation/>
    </div>
  )
}
