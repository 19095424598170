
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import TestsLeftSideBar from './TestsLeftSideBar'
import TestsRightSideBar from './TestsRightSideBar'
import { authState } from '../../../app/slices/authSlice'
import { TestType, fetchTests, setCurrentTest, testsState } from '../../../app/slices/TestsSlice'
import TestCodeBlock from './TestCodeBlock'
import TestsFeedback from './TestsFeedback'
import CreateTestButton from './CreateTestButton'
import CogLoader from '../../utils/shared/CogLoader'
import PageLoader from '../../utils/shared/PageLoader'
import StickyLeftTests from './StickyLeftTests'
import StickyRightTests from './StickyRightTests'
import StickyMainTests from './StickyMainTests'
import TestsPageGrid from './TestsPageGrid'

const Tests = () => {
  const { testId } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authState);
  const { tests, initLoading } = useAppSelector(testsState);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchTests(user.customer_id));
    }
  }, [user, dispatch]);


  useEffect(() => {
    if (testId) {
      const selectedTest = tests.find((test: TestType) => test.id === testId);
      if (selectedTest) {
        dispatch(setCurrentTest(selectedTest));
      } else {
        dispatch(setCurrentTest(tests[0]));
      }
    } else {
      dispatch(setCurrentTest(tests[0]));
    }
  }, [initLoading, testId]);

  
  if(initLoading) return <PageLoader/>

  return (
      
    <TestsPageGrid>
          <StickyLeftTests>
              <TestsLeftSideBar />
          </StickyLeftTests>
          <StickyMainTests>
            <TestCodeBlock />
            <TestsFeedback />
          </StickyMainTests>
          <StickyRightTests>
            <TestsRightSideBar />
          </StickyRightTests>
    </TestsPageGrid>
      
  );
};

export default Tests;