import React, { FC } from 'react'
import './StickyTests.css'

interface StickyLeftTestsProps {
  children: React.ReactNode
}

const StickyLeftTests:FC<StickyLeftTestsProps> = ({children}) => {
  return (
    <div className={`sticky-left-tests`}>{children}</div>
  )
}

export default StickyLeftTests