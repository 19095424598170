import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { GetRelatedRecordObject, GetFlowsByCustomerID, GetTestsByFlowId, CreateFlow } from '../apis/flowsApi';
import { TestType } from './TestsSlice';

export interface FlowType {
  id: string;
  created_at: string;
  related_record_id: string;
  related_record_object: any | null;
  customer_id: string;
  name: string;
  description: string;
  _steps: string | null;
  related_test: TestType | null;
  is_pending: boolean;
}

interface FlowsState {
  flows: FlowType[];
  currentFlow: FlowType | null;
  initLoading: boolean;
  recordLoading: boolean;
  testLoading: boolean;
  error: string | null;
  loadingCreation: boolean;
}

const initialState: FlowsState = {
  flows: [],
  currentFlow: null,
  initLoading: false,
  recordLoading: false,
  testLoading: false,
  error: null,
  loadingCreation: false
};

export const createFlow = createAsyncThunk(
  'flows/createFlow',
  async ({ FlowData }: any) => {
    try {
      const res = await CreateFlow(FlowData)
      return res.data
    } catch (err: any) {
      return err.message
    }
  }
)


export const fetchFlows = createAsyncThunk(
  'flows/fetchFlows',
  async (customer_id: any) => {
    const fetchedFlows = await GetFlowsByCustomerID(customer_id);
    return fetchedFlows.data as FlowType[];
  }
);

export const fetchRelatedTests = createAsyncThunk(
  'flows/getTestsByFlowId',
  async ({ flow_id, customer_id }: { flow_id: string; customer_id: string }, { getState }) => {
    const state = getState() as RootState;
    const fetchedRelatedTests = await GetTestsByFlowId(flow_id, customer_id);
    return {flow_id, fetchedRelatedTests: fetchedRelatedTests.data as TestType[]};
  }
);

export const fetchRelatedRecordObject = createAsyncThunk(
  'flows/fetchRelatedRecordObject',
  async ({ flowId, record_id }: { flowId: string | number; record_id: string }, { getState }) => {
    const state = getState() as RootState;
    const flow = state.flows.flows.find(flow => flow.id === flowId);
  
    if (flow?.related_record_object) {
      //alert('Already fetched')
      return { flowId, related_record_object: flow.related_record_object };
    }

    const relatedRecordObject = await GetRelatedRecordObject(record_id);
    return { flowId, related_record_object: relatedRecordObject };
  }
);

const flowsSlice = createSlice({
  name: 'flows',
  initialState,
  reducers: {
    setCurrentFlow(state, action: PayloadAction<FlowType | null>) {
      state.currentFlow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
          .addCase(createFlow.pending, (state, action) => {
            state.loadingCreation = true
          })
          .addCase(
            createFlow.fulfilled,
            (state, action: PayloadAction<any>) => {
              state.loadingCreation = false
              // flowsAdapter.setOne(state, action.payload[0])
            }
          )
          .addCase(
            createFlow.rejected,
            (state, action: PayloadAction<any>) => {
              state.loadingCreation = false
              state.error = action.payload
            }
          )
      .addCase(fetchFlows.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchFlows.fulfilled, (state, action: PayloadAction<FlowType[]>) => {
        state.initLoading = false;
        state.flows = action.payload;
      })
      .addCase(fetchFlows.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(fetchRelatedRecordObject.pending, (state) => {
        state.recordLoading = true;
      })
      .addCase(fetchRelatedRecordObject.fulfilled, (state, action: PayloadAction<{ flowId: string | number; related_record_object: any }>) => {
        state.recordLoading = false;
        const { flowId, related_record_object } = action.payload;
        const flow = state.flows.find(flow => flow.id === flowId);
        if (flow) {
          flow.related_record_object = related_record_object;
        }
        if (state.currentFlow && state.currentFlow.id === flowId) {
          state.currentFlow.related_record_object = related_record_object;
        }
      })
      .addCase(fetchRelatedRecordObject.rejected, (state, action) => {
        state.recordLoading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(fetchRelatedTests.pending, (state) => {
        state.testLoading = true;
      })
      .addCase(fetchRelatedTests.fulfilled, (state, action: PayloadAction<{ flow_id: string; fetchedRelatedTests: TestType[] }>) => {
        state.testLoading = false;
        const { flow_id, fetchedRelatedTests } = action.payload;
        const flow = state.flows.find(flow => flow.id === flow_id);
        if (flow) {
          flow.related_test = fetchedRelatedTests[0];
        }
        if (state.currentFlow && state.currentFlow.id === flow_id) {
          state.currentFlow.related_test = fetchedRelatedTests[0];
        }
      })
      .addCase(fetchRelatedTests.rejected, (state, action) => {
        state.testLoading = false;
        state.error = action.error.message ?? null;
      });
  },
});

export const { setCurrentFlow } = flowsSlice.actions;
export const flowsState = (state: RootState) => state.flows;
export default flowsSlice.reducer;
