import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import CogLoader from '../../utils/shared/CogLoader';
import moment from 'moment';
import { TestType, setCurrentTest, testsState } from '../../../app/slices/TestsSlice';
import CreateTestButton from './CreateTestButton';
import DownloadButton from '../../utils/elements/DownloadButton';
import './TestsLeftSideBar.css';
import TestLeftSideBarItem from './TestLeftSideBarItem';
import Counter from '../../utils/elements/Counter';
import { flowsState } from '../../../app/slices/FlowsSlice';

const TestsLeftSidebar: FC = () => {
  const { tests, initLoading, currentTest } = useAppSelector(testsState);
  const { flows } = useAppSelector(flowsState);
  const [presentedTestId, setPresentedTestId] = useState<string | number | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { testId } = useParams();

  const handleTestClick = (test: TestType) => {
    dispatch(setCurrentTest(test));
    setPresentedTestId(test.id);
    navigate(`/tests/${test.id}`);
  };

  useEffect(() => {
    if (currentTest) {
      setPresentedTestId(currentTest.id);
    } else if (tests.length > 0) {
      for (const test of tests) {
        if (!test.is_pending) {
          dispatch(setCurrentTest(test));
          setPresentedTestId(test.id);
          break;
        }
      }
    }
  }, [initLoading]);

  useEffect(() => {
    if (testId) {
      setPresentedTestId(testId);
    }
  }, [testId]);

  return (
    <div className="main-container">
      <div className="grid-container">
        <div className="sticky-header">
          <div
            className="cursor-pointer-nav"
            onClick={() => navigate('/tests')}
          >
            <h1 className="general-title">Generated Tests</h1>

          </div>
          <Counter title="Tests" end={tests.length} />
        </div>
        <div className="test-list-container">
          {tests.map((test: TestType) => {
            return (
              <TestLeftSideBarItem
                key={test.id}
                test={test}
                flow={flows ? flows.find(flow => flow.id === test.flow_id) : undefined}
                handleTestClick={() => handleTestClick(test)}
                isPresented={presentedTestId === test.id}
              />
            )
          })}
        </div>
        <div className="footer-buttons">
          <CreateTestButton />
          <DownloadButton
            data={tests}
            dataAttribute="_transcription"
            filenameAttribute="name"
            fileExtension="js"
            mimeType="text/javascript"
          />
        </div>
      </div>
    </div>
  );
};

export default TestsLeftSidebar;