import JSZip from 'jszip';

interface DownloadButtonProps<T> {
    data: T | T[];
    dataAttribute: keyof T;
    filenameAttribute: keyof T;
    fileExtension: string;
    mimeType: string;
}

const DownloadButton = <T,>({
    data,
    dataAttribute,
    filenameAttribute,
    fileExtension,
    mimeType
}: DownloadButtonProps<T>) => {
    // Handle download for a single item
    const handleDownload = (item: T) => {
        const dataContent = String(item[dataAttribute]);
        const filename = String(item[filenameAttribute]);

        const blob = new Blob([dataContent], { type: mimeType });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = `${filename}.${fileExtension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    // Initiates download for either a single item or an array of items
    const initiateDownload = async () => {
        if (Array.isArray(data)) {
            const zip = new JSZip();
            data.forEach(item => {
                const dataContent = String(item[dataAttribute]);
                const filename = String(item[filenameAttribute]);
                zip.file(`${filename}.${fileExtension}`, dataContent);
            });
            
            const blob = await zip.generateAsync({ type: 'blob' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = 'tests.zip';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } else {
            handleDownload(data);
        }
    };    

    const downloadIcon = (
        <svg
            className={`h-9 w-9 fill-tuto-primary transition duration-300`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            
        >
            {Array.isArray(data) ? (
                <>
                    <path fillRule="evenodd" d="M9.75 6.75h-3a3 3 0 0 0-3 3v7.5a3 3 0 0 0 3 3h7.5a3 3 0 0 0 3-3v-7.5a3 3 0 0 0-3-3h-3V1.5a.75.75 0 0 0-1.5 0v5.25Zm0 0h1.5v5.69l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72V6.75Z" clipRule="evenodd" />
                    <path d="M7.151 21.75a2.999 2.999 0 0 0 2.599 1.5h7.5a3 3 0 0 0 3-3v-7.5c0-1.11-.603-2.08-1.5-2.599v7.099a4.5 4.5 0 0 1-4.5 4.5H7.151Z" />
                    <title>Download All {data.length} Tests</title>
                </>
            ) : (
                <>
                <path d="M12 1.5a.75.75 0 0 1 .75.75V7.5h-1.5V2.25A.75.75 0 0 1 12 1.5ZM11.25 7.5v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V7.5h3.75a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h3.75Z" />
                <title>Download { (data as any).name.toLowerCase().includes("test") ? (data as any).name : `Test ${ (data as any).name}`}</title>
                </>
            )}
        </svg>
      );
    
      return (
        <button onClick={initiateDownload} style={{ border: 'black', background: 'none', cursor: 'pointer', paddingLeft: '0.5rem'}}>
          {downloadIcon}
        </button>
      );
    };

export default DownloadButton;


/*

Common MIME Types
Text Files:

Plain Text: text/plain
CSV (Comma-separated values): text/csv
CSS (Cascading Style Sheets): text/css
HTML (HyperText Markup Language): text/html
JavaScript: text/javascript (officially application/javascript, but text/javascript is commonly used)
Application Files:

JSON (JavaScript Object Notation): application/json
XML (eXtensible Markup Language): application/xml (if not readable from casual users, otherwise text/xml)
PDF (Portable Document Format): application/pdf
ZIP Archive: application/zip
Microsoft Word: application/msword
Microsoft Excel: application/vnd.ms-excel
ePub (Electronic Publication): application/epub+zip
Image Files:

JPEG Image: image/jpeg
PNG Image: image/png
GIF Image: image/gif
SVG (Scalable Vector Graphics): image/svg+xml
Audio/Video Files:

MPEG Audio: audio/mpeg
MP4 Audio: audio/mp4
MP4 Video: video/mp4
MPEG Video: video/mpeg
OGG Audio: audio/ogg
OGG Video: video/ogg
WebM Audio: audio/webm
WebM Video: video/webm
*/