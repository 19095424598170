import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FlowsLeftSideBar from './FlowsLeftSideBar';
import FlowsRightSideBar from './FlowsRightSideBar';
import { authState } from '../../../app/slices/authSlice';
import {
  FlowType,
  fetchFlows,
  setCurrentFlow,
  flowsState
} from '../../../app/slices/FlowsSlice';
import FlowCodeBlock from './FlowCodeBlock';
import PageLoader from '../../utils/shared/PageLoader';
import FlowsPageGrid from './FlowsPageGrid';
import StickyLeftFlows from './StickyLeftFlows';
import StickyMainFlows from './StickyMainFlows';
import StickyRightFlows from './StickyRightFlows';
import FlowFeedback from './FlowFeedback';

const Flows = () => {
  const { flowId } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authState);
  const { flows, initLoading } = useAppSelector(flowsState);
    const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchFlows(user.customer_id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (flowId) {
      const selectedFlow = flows.find((flow: FlowType) => flow.id === flowId);
      if (selectedFlow) {
        dispatch(setCurrentFlow(selectedFlow));
      } else {
        console.log("no selected flow")
        dispatch(setCurrentFlow(flows[0]));
      }
    } else {
      console.log("no flow_id")
      const firstNonPendingFlow = flows.find(flow => !flow.is_pending);
      if (flows.length > 0 && firstNonPendingFlow) {
        navigate(`/flows/${firstNonPendingFlow.id}`)
      }
      dispatch(setCurrentFlow(flows[0]));
    }
  }, [initLoading, flowId]);

  if (initLoading) return <PageLoader />;

  return (
    <FlowsPageGrid>
      <StickyLeftFlows>
        <FlowsLeftSideBar />
      </StickyLeftFlows>
      <StickyMainFlows>
        <FlowCodeBlock />
        <FlowFeedback />
      </StickyMainFlows>
      <StickyRightFlows>
        <FlowsRightSideBar />
      </StickyRightFlows>
    </FlowsPageGrid>
  );
};

export default Flows;
