import React from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { authState } from '../../../../app/slices/authSlice'

const ExtensionInstallation = () => {
  const { user } = useAppSelector(authState)
  return (
    <div>
      <h2 className="general-title">Record Extension Installation Guide</h2>
      <ol className="mt-6">
        <li>
          1. Click{' '}
          <a
            href="https://chrome.google.com/webstore"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{' '}
          to download the Record Extension. You will be redirected to the Google
          Chrome Web Store.
        </li>
        <li>
          2. After installing the Record Extension, navigate to the domain that
          you wish to connect to Record.
        </li>
        <li>
          3. Find the Record Extension icon in the top-right corner of your
          browser window. It may be located in the browser toolbar or in the
          extension menu. Click on this icon.
        </li>
        <li>
          4. This will open a new interface (see picture below). Here, you need
          to insert your Record API-KEY into the provided input field, then hit
          "Connect".
          <img
            className="my-4"
            src="https://assets.torecord.it/record-ext.png"
          />
        </li>

        <li>
          <strong>Your Record API-KEY:</strong> {user.customer_id}
        </li>
        <li>
          If you see a message indicating that someone has already claimed this
          domain, it means the domain has been previously associated with
          another Record API-KEY. If this is your company's domain and you
          believe this is a mistake, please contact us.
        </li>
        <li>
          5. After clicking "Connect", a new tab will open showing the Record
          dashboard.
        </li>
        <li>
          6. Navigate to "Settings" {'->'} "Domains". Find the domain you just added in
          the list.
        </li>
        <li>
          7. On the row corresponding to your domain, navigate to the last
          column and hit "Activate".
        </li>
        <li>
          8. Now, go back to the tab with your domain (the one where you
          initially hit "Connect"), and refresh the page.
        </li>
        <li>
          9. To verify that the extension has been correctly configured, click
          on the Record Extension icon again. If you see the following
          interface, you're all set. If not, please contact us.
        </li>
      </ol>
    </div>
  )
}

export default ExtensionInstallation
