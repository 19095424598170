import React, { FC } from 'react';
import './StickyFlows.css';

interface StickyMainFlowsProps {
  children: React.ReactNode;
}

const StickyMainFlows: FC<StickyMainFlowsProps> = ({ children }) => {
  return (
    <div className="sticky-main-flows">
      {children}
    </div>
  );
};

export default StickyMainFlows;