import React, { FC } from 'react'
import './StickyFlows.css'

interface StickyRightFlowsProps {
  children: React.ReactNode
}

const StickyRightFlows: FC<StickyRightFlowsProps> = ({ children }) => {
  return (
    <div className={`sticky-right-flows`}>
      {children}
    </div>
  )
}

export default StickyRightFlows
