import React, { FC } from 'react'
import './StickyTests.css'

interface StickyRightTestsProps {
  children: React.ReactNode
}

const StickyRightTests: FC<StickyRightTestsProps> = ({ children }) => {
  return (
    <div className={`sticky-right-tests`}>
      {children}
    </div>
  )
}

export default StickyRightTests
