import { FC, Fragment } from 'react'
import MainFlowView from './MainFlowView'
import TestCodeBlock from '../Tests/TestCodeBlock';




const FlowsRightSideBar: FC<{}> = () => {

  return (
    <Fragment>
      <>
        <div className="bg-gray-100 border rounded-xl flex-grow overflow-y-auto mt-8 min-h-0">
          <MainFlowView />
          <TestCodeBlock useFlow={true}/>
        </div>
      </>
    </Fragment>
  );
}

export default FlowsRightSideBar
