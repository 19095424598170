import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import CogLoader from '../../utils/shared/CogLoader';
import moment from 'moment';
import {
  FlowType,
  setCurrentFlow,
  flowsState
} from '../../../app/slices/FlowsSlice';
import CreateFlowButton from './CreateFlowButton';
import './FlowsLeftSideBar.css';
import FlowLeftSideBarItem from './FlowLeftSideBarItem';
import Counter from '../../utils/elements/Counter';

const FlowsLeftSidebar: FC = () => {
  const { flows, initLoading, currentFlow } = useAppSelector(flowsState);
  const [presentedFlowId, setPresentedFlowId] = useState<
    string | number | null
  >(null);
  const [showPending, setShowPending] = useState(true);
  const [flowPresentedCount, setflowPresentedCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { flowId } = useParams();

  const handleFlowClick = (flow: FlowType) => {
    dispatch(setCurrentFlow(flow));
    setPresentedFlowId(flow.id);
    navigate(`/flows/${flow.id}`);
  };

  useEffect(() => {
    setflowPresentedCount(flows.length)
    const firstNonPendingFlow = flows.find(flow => !flow.is_pending);
    if (currentFlow) {
      setPresentedFlowId(currentFlow.id);
    } else if (flows.length > 0 && firstNonPendingFlow) {
      dispatch(setCurrentFlow(firstNonPendingFlow));
      setPresentedFlowId(firstNonPendingFlow.id);
      navigate(`/flows/${firstNonPendingFlow.id}`)
    }
  }, [initLoading]);

  useEffect(() => {
    if (flowId) {
      setPresentedFlowId(flowId);
    }
  }, [flowId]);


  useEffect(() => {
    if (showPending) {
      setflowPresentedCount(flows.length)
    } else {
      setflowPresentedCount(flows.filter((flow) => !flow.is_pending).length)
    }
  }, [showPending]);

  return (
    <div className="main-container">
      <div className="grid-container">
        <div className="sticky-header">
          <div className="header-row">
            <div
              className="cursor-pointer-nav"
              onClick={() => navigate('/flows')}
            >
              <h1 className="general-title">Scenarios</h1>
            </div>
            <Counter title="Scenarios" end={flowPresentedCount} />
          </div>
          {flows.length > 0 && (
            <div className="toggle-container">
              <label className="toggle-label">
                <div
                  className={`toggle-switch ${showPending ? 'active' : ''}`}
                  onClick={() => setShowPending(!showPending)}
                >
                  <div className="toggle-knob"></div>
                </div>
                <span className='text-md'>Show Pending</span>
              </label>
            </div>
          )}
        </div>
        <div className="flow-list-container">
          {flows
            .filter((flow) => showPending || !flow.is_pending)
            .map((flow: FlowType) => (
              <FlowLeftSideBarItem
                key={flow.id}
                flow={flow}
                handleFlowClick={() => handleFlowClick(flow)}
                isPresented={presentedFlowId === flow.id}
              />
            ))}
        </div>
        <div className="footer-buttons"></div>
        <CreateFlowButton />
      </div>
    </div>
  );
};

export default FlowsLeftSidebar;
