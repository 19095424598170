import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  customerDomainsSelectors,
  setCopiedLink
} from '../../../app/slices/customerDomainsSlice'
import { addNewNotification, setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import Button from '../../utils/elements/Button'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { encryptRedirectionData } from '../../utils/encryption'
import CONFIG from '../../../config/config'
import {
  PlayIcon,
  UserPlusIcon,
  ClipboardIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline'
import { authState } from '../../../app/slices/authSlice'
import Modal from '../../utils/layout/modal'
import { createFlow } from '../../../app/slices/FlowsSlice'
import { useNavigate } from 'react-router-dom'

const CreateFlowButton = () => {
  const dispatch = useAppDispatch()
  const domains = useAppSelector(customerDomainsSelectors.selectAll)
  const { user } = useAppSelector(authState)
  const verifiedDomains: any =
    domains &&
    domains.length > 0 &&
    domains.filter((domain) => {
      return domain.status_id.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
    })
  const [selectedDomain, setSelectedDomain] = useState('')
  const [copied, setIsCopied] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [textValue, setTextValue] = useState('');
  const [title, setTitle] = useState("");

  const navigate = useNavigate()



  useEffect(() => {
    const producionDomain = domains.find((domain: any) => domain.env_name === 'Production');
    setSelectedDomain((prev) => producionDomain?.origin ? producionDomain.origin : prev);
  }, [domains])


  const openScenerioModal = () => {
    setPopupOpen(true)
  }

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
  };

  const handleSendText = async () => {
    if (!title.trim()) {
      alert("The title cannot be empty!");
      return;
    }
    setTextValue('');
    setTitle('');
    const FlowData: any = {
      flow_name: title,
      flow_description: textValue,
      customer_id: user.customer_id,
    }
    const createdFlow = await dispatch(
      createFlow({ FlowData })
    )
    if (createdFlow.payload.id) {
      dispatch(
        addNewNotification({
          text: `Scenario ${title} Created successfully`,
          title: 'Scenario Created!',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
      navigate(`/flows/${createdFlow.payload[0].id}`)
    }

  };

  const handleCreateFlow = () => {
    console.log("click on Record")
    Mixpanel.track('Click on Copy Link', {
      link: `${selectedDomain}/?isrecording=true&userId=${user.id}`
    });
    setIsCopied(true);
    console.log(selectedDomain, user.id, user.name, user.company_name);
    const encData = encryptRedirectionData(
      window.location.href,
      selectedDomain,
      user.id,
      user.name,
      user.company_name
    ) as string;
    const copiedLink = `${CONFIG.dashboard}links?redirect=${encodeURIComponent(encData)}`;
    dispatch(setCopiedLink(copiedLink));
    navigator.clipboard.writeText(copiedLink);
    dispatch(setCoppiedTooltip(true));
    window.open(copiedLink, '_blank');
    setPopupOpen(false)
  }


  return (
    <div className="flex items-center w-full space-x-2">
      <Button
        inverted={false}
        loading={false}
        disabled={selectedDomain == '' ? true : false}
        text={selectedDomain == '' ? "Can't Find Domain" : "Create New Scenario"}
        bgColor={selectedDomain == '' ? 'bg-gray-300' : 'bg-tuto-primary'}
        textColor={selectedDomain == '' ? 'text-tuto-primary' : 'text-white'}
        type="button"
        withIcon={false}
        clickFunction={() => openScenerioModal()}
        isFullWidth={true}
        textSize='lg'
      />
      <Modal
        type="info"
        buttonText={'Or - Record Yourself'}
        cancelButtonText={'Send as Text'}
        cancelButtonFunction={handleSendText}
        title={'New Scenario'}
        buttonAction={handleCreateFlow}
        openFunction={setPopupOpen}
        isOpen={popupOpen}
      >
        <input
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter a title for the scenario"
          className="w-full mb-4 p-2 border border-gray-300 rounded-md"
        />
        <textarea
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          placeholder="Write down what scenario you want to test"
          className="w-full h-24 p-2 border border-gray-300 rounded-md"
        />
      </Modal>
    </div>
  );
};


export default CreateFlowButton


