import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
  GlobeAltIcon,
  UserGroupIcon,
  CodeBracketIcon,
  DocumentTextIcon,
  CogIcon,
  CreditCardIcon,
  PuzzlePieceIcon
} from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import { Mixpanel } from '../../../utils/mixPanel/mixPanel'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { authState, logOutUser } from '../../../../app/slices/authSlice'
import GrantAccess from '../../../Auth/GrantAccess'
import { PhotoIcon } from '@heroicons/react/20/solid'

const navigation = [
  { name: 'Your Account', href: '', icon: UserCircleIcon, current: false },
  {
    name: 'Domains',
    href: '/settings/domains',
    icon: GlobeAltIcon,
    current: false
  },
  {
    name: 'Installation',
    href: '/settings/installation',
    icon: CodeBracketIcon,
    current: false
  },
  {
    name: 'Teammates',
    href: '/settings/teammates',
    icon: UserGroupIcon,
    current: false
  },
  // {
  //   name: 'Collecting',
  //   href: '/settings/collecting',
  //   icon: PhotoIcon,
  //   current: false
  // },
  // {
  //   name: 'Docs',
  //   href: 'https://sandbox.torecord.it/docs',
  //   icon: DocumentTextIcon,
  //   current: false
  // },
  // {
  //   name: 'Integrations',
  //   href: '/settings/integrations',
  //   icon: CogIcon,
  //   current: false
  // },
  // {
  //   name: 'Extension',
  //   href: '/settings/extension',
  //   icon: PuzzlePieceIcon,
  //   current: false
  // }
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SideBar = () => {
  const dispatch = useAppDispatch()
  const { isPlayground } = useAppSelector(authState)

  return (
    <>
      <div className="md:flex md:w-64 md:flex-col sidebar fixed z-20">
        {/* Sidebar cmponent, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow h-full bg-white overflow-y-auto">
          <div className="mt-5 flex-grow flex flex-col ">
            <div className="flex flex-1 flex-col justify-between pb-3">
              <nav className="flex-1 px-2 pb-4 space-y-1 relative">
              {/* <StepByStep /> */}
                {navigation.map((item) => (
                  <NavLink
                  {...({'data-cy':'your-account'})}
                    end
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'bg-blue-100 text-gray-900'
                          : 'text-gray-600 hover:bg-blue-100 hover:text-gray-900',
                        'group flex justify-center items-center md:justify-start px-2 py-2 text-sm font-medium rounded-md'
                      )
                    }
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-gray-700'
                          : 'text-gray-600 group-hover:text-gray-500',
                        'md:mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">{item.name}</span>
                   
                  </NavLink>
                ))}
               

                 {/* <a
                  {...({'data-cy':'your-account'})}
                    target={'_blank'}
                    
                    href={'https://torecord.it/docs'}
                    className={ 'text-gray-600 hover:bg-blue-100 hover:text-gray-900 group flex justify-center items-center md:justify-start px-2 py-2 text-sm font-medium rounded-md'}
                  >
                    <CreditCardIcon
                      className={classNames(
                        false
                          ? 'text-gray-700'
                          : 'text-gray-600 group-hover:text-gray-500',
                        'md:mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Docs</span>
                   
                  </a> */}
               
              </nav>
             
              <div className="px-2 ">
                <div
                  className={
                    'hidden group md:flex flex-grow items-center px-2 py-2 text-sm mb-2 font-medium rounded-md cursor-pointer'
                  }
                >
                  
                </div>
                <div
                  onClick={() => {
                    dispatch(logOutUser())
                  }}
                  className={isPlayground ? 'group flex flex-grow items-center justify-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer mb-16' :
                    'group flex flex-grow items-center justify-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer'
                  }
                >
                  <ArrowLeftOnRectangleIcon
                    className={
                      'md:mr-3 rotate-180 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500'
                    }
                  />
                  <span className="hidden sm:block">Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StepByStep /> */}
    </>
  )
}

export default SideBar
