import React, { FC } from 'react';
import './StickyTests.css';

interface StickyMainTestsProps {
  children: React.ReactNode;
}

const StickyMainTests: FC<StickyMainTestsProps> = ({ children }) => {
  return (
    <div className="sticky-main-tests">
      {children}
    </div>
  );
};

export default StickyMainTests;