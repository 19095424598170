import React from 'react'
import Tabs from './Tabs'
import { CalendarIcon, CodeBracketIcon, PaperAirplaneIcon, PuzzlePieceIcon } from '@heroicons/react/24/solid'
import InstallationBlock from './InstallationBlock'
import BookDemo from '../BookDemo'
import Instructions from './Instructions'
import ExtensionInstructions from './ExtensionInstructions'


interface Tab {
  title: string
  icon: JSX.Element // This assumes your icon is some form of JSX, such as from react-icons
  content: JSX.Element // The content of the tab, which can be any React component
  
}

const tabs: Tab[] = [
  {
    title: 'Book Onboarding',
    icon: <CalendarIcon  className='h-8 w-8 text-tuto-light-blue'/>,
    content: <BookDemo />
  },
  {
    title: 'Install by Yourself',
    icon: <CodeBracketIcon className='h-8 w-8 text-tuto-purple stroke-2'/>,
    content: <InstallationBlock location='installation'/> 
  },
  // {
  //   title: 'Send Installation Instructions',
  //   icon: <PaperAirplaneIcon className='h-8 w-8 text-green-600 '/>,
  //   content: <Instructions /> 
  // },
  // {
  //   title: 'Record Extension',
  //   icon: <PuzzlePieceIcon className='h-8 w-8 text-red-600 '/>,
  //   content: <ExtensionInstructions /> 
  // }
]

const InstallationTabs = () => {
  return (
    <div className='w-full'>
      <Tabs tabs={tabs} />
    </div>
  )
}

export default InstallationTabs
