import { useEffect, useState } from "react"
import { decryptData } from "../components/utils/encryption"
import { Mixpanel } from "../components/utils/mixPanel/mixPanel"
import './Links.css'

const Links = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const rediretData = searchParams.get('redirect') || ''
    const data = decodeURIComponent(rediretData)
    let decData: any
    if(data !== ''){
        decData = decryptData(data)
    }
    const [redirectUrl, setRedirectUrl] = useState('')

    useEffect(() => {
        if(decData && decData !== ''){
            Mixpanel.track("Redirect Page Opened", {
                customer_name: decData.record_customer_name,
                domain: decData.record_redirect_url,
            })
            if(decData.record_redirect_url){
                document.cookie = `username=${decData.record_redirect_url}; expires=Thu, 18 Dec 2013 12:00:00 UTC`
                setRedirectUrl(decData.record_redirect_url)
            }
        }
    }, [decData])

    useEffect(() => {
        if(redirectUrl !== ''){
            setTimeout(() => {
                const message = {
                    name: "setSessionStorage",
                    data: {
                        origin_url: decData.origin_url,
                        record_redirect_url: decData.record_redirect_url,
                        record_user_id: decData.record_user_id,
                        env: decData.env,
                        redirected_from: window.location.href,
                        record_user_name: decData.record_user_name,
                        record_customer_name: decData.record_customer_name,
                        record_ticket_id: decData.record_ticket_id
                    }
                }
                const openWin = window.open(redirectUrl, '_blank')
                let i = 0;
                let intervalId = setInterval(function() {
                    if(openWin){
                        openWin.postMessage(message, '*')
                    }
                    i++;
                    if (i === 30) {
                        clearInterval(intervalId)
                    }
                }, 2000);
        }, 4000);
        }
    }, [redirectUrl])

    window.addEventListener('message', (event) => {
        if(event.data === 'sessionStoageDone'){
            window.opener = null;
            window.open("", "_self");
            window.close();
        }
    }, true)

    const openLink = () => {
                const message = {
                    name: "setSessionStorage",
                    data: {
                        origin_url: decData.origin_url,
                        record_redirect_url: decData.record_redirect_url,
                        record_user_id: decData.record_user_id,
                        env: decData.env,
                        redirected_from: window.location.href,
                        record_user_name: decData.record_user_name,
                        record_customer_name: decData.record_customer_name,
                        record_ticket_id: decData.record_ticket_id
                    }
                }
                const openWin = window.open(redirectUrl, '_blank')
                let i = 0;
                let intervalId = setInterval(function() {
                    if(openWin){
                        openWin.postMessage(message, '*')
                    }
                    i++;
                    if (i === 30) {
                        clearInterval(intervalId)
                    }
                }, 2000);
    }

    return(
         <div className="p-10 w-full flex flex-col items-center justify-center">
            <div className="mb-36">
                <div className="text-3xl sm:text-3xl font-semibold text-light-black">
                    We will redirect you shortly to
                </div>
                <div className="text-center sm:text-lg font-semibold text-light-black mt-4">
                    {decData.record_redirect_url}
                </div>
            </div>
            <div className='purple'></div>
            <div className='yellow'></div>
            <div className='blue'></div>
            <div id="wifi-loader">
                <svg className="circle-outer" viewBox="0 0 86 86">
                <circle className="back" cx="43" cy="43" r="40"></circle>
                <circle className="front" cx="43" cy="43" r="40"></circle>
                <circle className="new" cx="43" cy="43" r="40"></circle>
                </svg>
                <svg className="circle-middle" viewBox="0 0 60 60">
                <circle className="back" cx="30" cy="30" r="27"></circle>
                <circle className="front" cx="30" cy="30" r="27"></circle>
                </svg>
                <svg className="circle-inner" viewBox="0 0 34 34">
                <circle className="back" cx="17" cy="17" r="14"></circle>
                <circle className="front" cx="17" cy="17" r="14"></circle>
                </svg>
            </div>
            <div className="fixed bottom-0">
                <div className="mb-2 text-center font-semibold text-light-black text-lg">
                    <div className="text-sm mb-2">
                        <a href={`${decData.record_redirect_url}`} target="_blank" onClick={() => {
                            Mixpanel.track("User Clicked On Open Link", {
                                customer_name: decData.record_customer_name,
                                domain: decData.record_redirect_url,
                            })
                            openLink()
                            }}>Click here if the link not opening automatically</a>
                    </div>
                    powered by
                </div>
                <div className="flex justify-center">
                <a href="https://torecord.it?utm_source=from_redirect_page">
                <img
                    className="mb-12 w-24 xl:w-24"
                    src="https://assets.torecord.it/recordlogoblack.png"
                    />
                </a>
                </div>
            </div>
        </div>
    )
}
export default Links