import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  customerDomainsSelectors,
  setCopiedLink
} from '../../../app/slices/customerDomainsSlice'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import Button from '../../utils/elements/Button'
import { Mixpanel } from '../../utils/mixPanel/mixPanel'
import { encryptRedirectionData } from '../../utils/encryption'
import CONFIG from '../../../config/config'
import {
  PlayIcon,
  UserPlusIcon,
  ClipboardIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline'
import { authState } from '../../../app/slices/authSlice'

const CreateTestButton = () => {
  const dispatch = useAppDispatch()
  const domains = useAppSelector(customerDomainsSelectors.selectAll)
  const { user } = useAppSelector(authState)
  const verifiedDomains: any =
    domains &&
    domains.length > 0 &&
    domains.filter((domain) => {
      return domain.status_id.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
    })
  const [selectedDomain, setSelectedDomain] = useState('')
  const [copied, setIsCopied] = useState(false)

  useEffect(() => { 
    const producionDomain = domains.find((domain: any) => domain.env_name === 'Production');
    setSelectedDomain((prev)=> producionDomain?.origin ? producionDomain.origin : prev);
    }, [domains])

  const handleCreateTest = () => {
    // TODO: download all tests



    // Mixpanel.track('Click on Copy Link', {
    //   link: `${selectedDomain}/?isrecording=true&userId=${user.id}`
    // });
    // setIsCopied(true);
    // console.log(selectedDomain, user.id, user.name, user.company_name);
    // const encData = encryptRedirectionData(
    //   window.location.href,  
    //   selectedDomain,
    //   user.id,
    //   user.name,
    //   user.company_name
    // ) as string;
    // const copiedLink = `${CONFIG.dashboard}links?redirect=${encodeURIComponent(encData)}`;
    // dispatch(setCopiedLink(copiedLink));
    // navigator.clipboard.writeText(copiedLink);
    // dispatch(setCoppiedTooltip(true));
    // window.open(copiedLink, '_blank');
  }


    return (
        <div className="flex items-center w-full space-x-2">
          {/* <select
            id="domain"
            name="domain"
            className="focus:border-tuto-primary h-full py-0 pl-2 pr-7 border-transparent bg-transparent w-full text-gray-500 text-xs sm:text-sm rounded-md"
            onChange={(e) => {
              if (selectedDomain !== e.target.value) {
                Mixpanel.track('Switch Domain for Copy', {
                  'old domain': selectedDomain,
                  'new domain': e.target.value
                });
              }
              setSelectedDomain(e.target.value);
              const encData = encryptData(
                e.target.value,
                user.id,
                user.name,
                user.company_name
              ) as string;
              dispatch(setCopiedLink(`${CONFIG}/links?redirect=${encData}`));
              navigator.clipboard.writeText(
                `${CONFIG.dashboard}links?redirect=${encodeURIComponent(encData)}`
              );
              dispatch(setCoppiedTooltip(true));
            }}
          >
            {!verifiedDomains && 
            <option disabled>No Domains Installed</option>}
            {verifiedDomains &&
              verifiedDomains.map((domain: any) => (
                <option key={domain.id} value={domain.origin}>
                  {domain.origin.toUpperCase()}
                </option>
              ))}
          </select> */}
        <Button
        inverted={false}
        loading={false}
        disabled={selectedDomain == '' ? true : false}
        text={"Download All Tests"}
        bgColor={selectedDomain == '' ? 'bg-gray-300' : 'bg-tuto-primary'}
        textColor={selectedDomain == '' ? 'text-tuto-primary' : 'text-white'}
        type="button"
        withIcon={false}
        clickFunction={() => handleCreateTest()}
        isFullWidth={true}
        textSize='lg'
      />
    </div>
  );
};


export default CreateTestButton


