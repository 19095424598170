import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { uiState } from '../../../app/slices/uiSlice'
import { Mixpanel } from '../mixPanel/mixPanel'

interface ModalProps {
  openFunction: any
  buttonAction: any
  title: string
  buttonText: string
  cancelButtonText?: string
  cancelButtonFunction?: any
  children: React.ReactNode
  isOpen: boolean
  type: 'danger' | 'info'
  dataCy?: string
}

const Modal: React.FC<ModalProps> = ({
  openFunction,
  buttonAction,
  title,
  isOpen,
  buttonText,
  cancelButtonText,
  cancelButtonFunction,
  children,
  type,
  dataCy
}) => {

  const dispatch = useAppDispatch()

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={isOpen} as={Fragment} {...(dataCy ? { 'data-cy': `${dataCy}` } : {})}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={openFunction}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="flex items-center space-x-4">
                    <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${type === 'danger' ? 'bg-red-100' : 'bg-tuto-secondary'} sm:mx-0 sm:h-10 sm:w-10`}>
                      {type === 'danger' ?

                        <ExclamationCircleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        /> :
                        <InformationCircleIcon
                          className="h-6 w-6 text-tuto-primary"
                          aria-hidden="true"
                        />
                      }
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                  </div>
                    <div className="mt-2">
                      {children}
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`w-full transition duration-300 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${type === 'danger' ? 'bg-red-600 hover:bg-red-700' : 'bg-tuto-primary hover:bg-tuto-secondary hover:text-tuto-primary'} text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={() => buttonAction(false)}
                  >
                    {buttonText}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      cancelButtonFunction && cancelButtonFunction()
                      dispatch(openFunction(false))
                    }
                    }
                    ref={cancelButtonRef}
                  >
                    {cancelButtonText ? cancelButtonText : 'Cancel'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
