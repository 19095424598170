/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  customerDomainsState,
  customerDomainsSelectors
} from '../../../app/slices/customerDomainsSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCoppiedTooltip } from '../../../app/slices/notificationsSlice'
import {
  authState,
  loginPlayground,
  logOutUser
} from '../../../app/slices/authSlice'
import { Mixpanel } from '../mixPanel/mixPanel'
import Steps from './Steps'
import StepByStep from './StepByStep'
import {
  uiState,
  toggleSlider,
  setOnboardingMode
} from '../../../app/slices/uiSlice'
import {
  addNewNotification,
  notificationsSelectors
} from '../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'
import { ZendeskAPI } from 'react-zendesk'
import { CogIcon } from '@heroicons/react/20/solid'

const navigation = [
  //{ name: 'Inbox', href: '/', current: true },
  // { name: 'Issues', href: '/issues', current: false },
  //{ name: 'Settings', href: '/settings', current: false },
  // { name: 'Help', href: '/settings', current: false }
  { name: 'Scenarios', href: '/flows', current: false },
  { name: 'Tests', href: '/tests', current: false },
  { name: 'Investigations', href: '/investigate', current: false }
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const NavBar = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isLoggedIn, user, isPlayground } = useAppSelector(authState)
  const { onBoardingMode } = useAppSelector(uiState)
  const domains = useSelector(customerDomainsSelectors.selectAll)
  const notifications = useSelector(notificationsSelectors.selectAll)
  // const verifiedDomains: any =
  //   domains &&
  //   domains.length > 0 &&
  //   domains.filter((domain) => {
  //     return domain.status_id.match('7b1a3e3e-4270-402e-bae3-ea4cb6e8e1b1')
  //   })

  // useEffect(() => {
  //   if (user) {
  //     dispatch(setOnboardingMode(user.is_onboarding_mode))
  //   }
  // }, [user])

  const notifyCodeInstallation = (route: string) => {
    if (route === 'Home' && domains.length === 0) {
      const hasNotification = notifications.some(
        (notification) => notification.title === 'Oops...'
      )
      if (!hasNotification) {
        dispatch(
          addNewNotification({
            text: `You will be able to access this page only after code installation`,
            id: uuidv4(),
            title: 'Oops...',
            type: 'Info',
            timer: false,
            button: true,
            buttonText: 'See How To Install',
            buttonFunction: () => window.open('https://www.youtube.com/watch?v=7myFkjPbGzg', 'blank')
          })
        )
      }
    }
  }


  // const openZe = () => {
  //   ZendeskAPI('webWidget', 'show')
  //   ZendeskAPI('webWidget', 'open')
  //   ZendeskAPI('webWidget:on', 'close', function () {
  //     ZendeskAPI('webWidget', 'hide')
  //   })
  // }

  return (
    <div className="fixed top-0 right-0 left-0 z-30">
      {/* {!isPlayground && (<><StepByStep
        title={discoverSteps[1].title}
        description={discoverSteps[1].description}
        number={discoverSteps[1].number} />
        <StepByStep
          title={discoverSteps[3].title}
          description={discoverSteps[3].description}
          number={discoverSteps[3].number} />
          <StepByStep
          title={discoverSteps[4].title}
          description={discoverSteps[4].description}
          number={discoverSteps[4].number} />
          </>)} */}
      {/* {isPlayground && !recordDiscoverMode && (<><StepByStep
        title={pgDiscoverSteps[1].title}
        description={pgDiscoverSteps[1].description}
        number={pgDiscoverSteps[1].number} />
       
          
          <StepByStep
          title={pgDiscoverSteps[5].title}
          description={pgDiscoverSteps[5].description}
          number={pgDiscoverSteps[5].number} />
          </>)} */}

      <div className="min-h-full">
        <Disclosure
          as="nav"
          className="bg-tuto-primary border-b border-gray-200"
        >
          {({ open }) => (
            <>
              <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex">
                    <div className="flex-shrink-0 flex items-center">
                      <img
                        className={`${!onBoardingMode ? 'cursor-pointer block lg:hidden h-8 w-auto' : 'block lg:hidden h-8 w-auto' }`}
                        src="https://assets.torecord.it/recordlogowhite.png"
                        alt="Workflow"
                        onClick={() => {if(!onBoardingMode){
                            
                            navigate('/')
                          }
                        }}
                      />
                      <img
                        className={`${!onBoardingMode ? 'cursor-pointer hidden lg:block h-8 w-auto' : 'hidden lg:block h-8 w-auto' }`}
                        src="https://assets.torecord.it/recordlogowhite.png"
                        alt="tuto"
                        onClick={() => {if(!onBoardingMode){
                          
                          navigate('/')
                        }
                      }}
                      />
                    </div>
                    <div
                      className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
                      {...{ 'data-cy': 'array-home-records-settings' }}
                    >
                      {isLoggedIn &&
                        !onBoardingMode &&
                        navigation.map((item) =>
                          item.name === 'Help' ? (
                            <div
                              // onClick={openZe}
                              key={item.name}
                              className="cursor-pointer border-transparent hover:border-gray-300 hover:text-gray-100 inline-flex text-white items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            >
                              {item.name}
                            </div>
                          ) : (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              onClick={() =>
                                item.name !== 'Help' && !isPlayground
                                  ? notifyCodeInstallation(item.name)
                                  : null
                              }
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? 'border-white'
                                    : 'border-transparent hover:border-gray-300 hover:text-gray-100',
                                  'inline-flex text-white items-center px-1 pt-1 border-b-2 text-sm font-medium'
                                )
                              }
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </NavLink>
                          )
                        )}
                      {onBoardingMode && (
                        <div className="text-white flex items-center border-l border-white my-2 p-2">
                          Setting up your account
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {/* <button
                      type="button"
                      className="mr-2 p-1 rounded-full text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    { ((isLoggedIn && !onBoardingMode) && false )? (
                      <div className="flex items-center justify-center text-white">
                        {/* <Steps /> */}
                        {/* {verifiedDomains && verifiedDomains.length > 0 && (
                          <button
                            type="button"
                            onClick={() => {
                              setIsCopied(!copied)
                              navigator.clipboard.writeText(`${copiedLink}`)
                              dispatch(setCoppiedTooltip(true))
                              Mixpanel.track('Copy Link to Record', {
                                customer_id: user.customer_id,
                                user_id: user.id,
                                email: user.email,
                                link: copiedLink
                              })
                            }}
                            className="bg-white text-tuto-primary py-2 px-4 shadow-xl rounded-lg hover:bg-gray-100 transition duration-300"
                          >
                            Copy Your Sharable Link
                          </button>
                        )} */}
                      </div>
                    ) : ( onBoardingMode && false ) ? (
                      <div
                        className="text-white cursor-pointer"
                        onClick={() => {
                          dispatch(logOutUser())
                        }}
                      >
                        <ArrowLeftOnRectangleIcon className="h-8 w-8 rotate-180" />
                      </div>
                    ) : ( false ) ?  (
                      <div>
                        <Link
                          to="/login"
                          onClick={() => {
                            Mixpanel.track('Go to Login From Public Record', {})
                          }}
                          className="text-white mr-4"
                        >
                          Alredy a member? Login
                        </Link>
                        <Link
                          to="/signup"
                          onClick={() =>
                            Mixpanel.track('Go to Sign Up From Public Record', {
                              location: 'Header'
                            })
                          }
                          className="bg-white text-tuto-primary py-2 px-4 shadow-xl rounded-lg hover:bg-gray-100 transition duration-300"
                        >
                          Get Record For Free
                        </Link>
                      </div>
                    ) : 
                    
                    <div
                  onClick={() => {
                    navigate('/settings')
                  }}
                  className={isPlayground ? 'group flex flex-grow items-center justify-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer mb-16' :
                    'group flex flex-grow items-center justify-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer'
                  }
                >
                 <CogIcon
                    className={
                    'md:mr-3 flex-shrink-0 h-6 w-6 text-gray-500 group-hover:text-gray-500'
                    }/>
                  <span className="hidden text-gray-400 sm:block group-hover:text-gray-600">Settings</span>
                </div>
                    
                    }
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="pt-2 pb-3 space-y-1">
                  {isLoggedIn &&
                    navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? 'border-white text-white'
                              : 'border-transparent text-white hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                          )
                        }
                      >
                        {item.name}
                      </NavLink>
                    ))}
                </div>
                {/* <div className="pt-4 pb-3 border-t border-gray-200">
                  <button
                    type="button"
                    className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div> */}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}

export default NavBar
