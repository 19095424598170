import React, { CSSProperties, useEffect, useState } from 'react';
import { Bars3BottomLeftIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '../../../app/hooks';
import { testsState, TestType } from '../../../app/slices/TestsSlice';
import './TestCodeBlock.css';
import { CopyBlock, a11yDark } from 'react-code-blocks';
import DownloadButton from '../../utils/elements/DownloadButton';
import { Buffer } from 'buffer';
import { flowsState } from '../../../app/slices/FlowsSlice';
import { buffer } from 'stream/consumers';

interface TestCodeBlockProps {
  useFlow?: boolean; // optional boolean prop
}

const TestCodeBlock: React.FC<TestCodeBlockProps> = ({ useFlow = false }) => {
  const { currentTest } = useAppSelector(testsState);
  const { currentFlow } = useAppSelector(flowsState);

  const [testToWorkWith, setTestToWorkWith] = useState<TestType | undefined | null>(undefined);
  const [presentedDate, setPresentedDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (useFlow) {
      setTestToWorkWith(currentFlow?.related_test ? currentFlow?.related_test : undefined)
      const dateString = currentFlow?.related_test?.created_at;
      if (dateString) {
        updateDate(dateString)
      }
    } else {
      setTestToWorkWith(currentTest ? currentTest : undefined)
    }
  }, [currentTest, currentFlow]);


  useEffect(() => {
    const dateString = testToWorkWith?.created_at;
    if (dateString) {
      updateDate(dateString)
    }
  }, [testToWorkWith]);

  const updateDate = (dateString: string) => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(date);

    setPresentedDate(formattedDate);
  };

  const getTestTitle = () => {
    if (testToWorkWith && presentedDate) {
      return `${testToWorkWith?.name} - ${presentedDate}`
    }
  }
  let bufferData = (testToWorkWith?._transcription as any)?.data;
  let codeString = 'We couldn’t find a code for this test.';

  if (bufferData) {
    const buffer = Buffer.from(bufferData);
    codeString = buffer.toString('utf-8');
  }

  return (
    <div className={`test-code-block-container`}>
      <div className="test-code-header">
        <div className="flex items-center">
          {!useFlow && currentTest ? (
            <div className="flex items-center">
              <Bars3BottomLeftIcon className="h-8 w-8 mr-4 text-tuto-primary" />
              <h1 className="general-title">{testToWorkWith?.name}</h1>
            </div>
          ) : testToWorkWith ? (
            <div className="flex items-center">
              <a
                href={`/tests/${testToWorkWith?.id}`}
                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
              >
                <ArrowLeftOnRectangleIcon className="h-8 w-8 mr-4 text-tuto-primary" />
                <h1 className="general-title">{getTestTitle()}</h1>
              </a>
            </div>
          ) : "No test Founded"}
        </div>
        <div className="pr-4">
          {!useFlow && currentTest ? (
            <DownloadButton
              data={currentTest}
              dataAttribute="_transcription"
              filenameAttribute="name"
              fileExtension="js"
              mimeType="text/javascript"
            />
          ) : ""}
        </div>
      </div>
      <div className={`code-container ${!useFlow ? 'scroll-enabled' : ''}`}>
        <CopyBlock
          language="jsx"
          text={codeString}
          showLineNumbers={true}
          theme={a11yDark}
          wrapLines={true}
          codeBlock
        />
      </div>
    </div>
  );
};

export default TestCodeBlock;
