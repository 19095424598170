import React, { useEffect, useState } from 'react';
import { TestType, setCurrentTest, testsState } from '../../../app/slices/TestsSlice';
import moment from 'moment';
import clock from '../../../Assets/clock.png'
import './TestLeftSideBarItem.css';
import { FlowType } from '../../../app/slices/FlowsSlice';


interface TestLeftSideBarItemProps {
    test: TestType;
    flow: FlowType | undefined;
    isPresented: boolean;
    handleTestClick: (test: TestType) => void;
}
const TestLeftSideBarItem: React.FC<TestLeftSideBarItemProps> = ({test, flow, isPresented, handleTestClick }) => {

  // console.log(flow)
  const formatDuration = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    const hoursFormatted = (hours < 10) ? `0${hours}` : hours;
    const minutesFormatted = (minutes < 10) ? `0${minutes}` : minutes;
    const secondsFormatted = (seconds < 10) ? `0${seconds}` : seconds;

    return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
  };

  return (
    <div
      key={test.id}
      onClick={!test.is_pending ? () => handleTestClick(test) : undefined }
      className={`test-item ${
        isPresented 
        ? 'test-item-selected'
        : 'test-item-unselected'} ${ 
        test.is_pending
        ? 'test-item-pending'
        : ''
        }`
      }
    >
      <div className="test-name">{test.name}</div>
      <div className="test-item-date">
        Created at {moment(new Date(test.created_at).toLocaleString()).format('DD/MM/YY HH:mm')}
      </div>
      <div className="test-duration">
        Duration: {formatDuration(test.related_record_duration || 0)}
      </div>
      <div className="test-item-type">
        {test.is_pending 
        ? 'Test Creation In Progress...'
        // : <span>Scenario: {flow?.name}</span>
        : <span>Produced from {test.is_from_record ? 'a record' : 'a session'}</span>
        }
      </div>
      {test.is_pending &&
        <div className='clock-icon-container'>
          <img src={clock} alt='clock icon' className='clock-icon'/>
        </div>
      }

    </div>
  );
};

export default TestLeftSideBarItem;