import CONFIG from '../../config/config'

const token = localStorage.getItem('id_token')

export const unprotectedGetRequest = async (apiEndpoint: string) => {
  const res = await fetch(`${CONFIG.api}/api/v1/${apiEndpoint}`)

  return res.json()
}

export const unprotectedConfigedGetRequest = async (apiEndpoint: string) => {
  const res = await fetch(`${'https://sandbox-api.torecord.it'}/api/v1/${apiEndpoint}`)

  return res.json()
}

export const getRequest = async (apiEndpoint: string) => {
  const res = await fetch(`${apiEndpoint}`)

  return res.json()
}

export const protectedGetRequest = async (apiEndpoint: string) => {
  const res = await fetch(`${CONFIG.api}/api/v1/${apiEndpoint}`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  })

  return res.json()
}

export const unprotectedPostRequest = async (
  apiEndpoint: string,
  body: any
) => {
  const res = await fetch(`${CONFIG.api}/api/v1/${apiEndpoint}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body)
  })

  return res.json()
}

export const protectedPostRequest = async (apiEndpoint: string, body: any) => {
  // console.log(body)
  const res = await fetch(`${CONFIG.api}/api/v1/${apiEndpoint}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    method: 'POST',
    body: JSON.stringify(body)
  })

  return res.json()
}

export const getAuthToken = (userId: string) => {
  let token

  token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.${userId}.idToken`
  )
  // console.log('token value', token)
  if (token) {
    return token
  }

  token = localStorage.getItem('id_token')
  // console.log('token value 2', token)
  if (token) {
    return token
  }
}
