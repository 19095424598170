import React, { FC } from 'react';
import './StickyFlows.css';

interface FlowsPageGridProps {
  children: React.ReactNode;
}

const FlowsPageGrid: FC<FlowsPageGridProps> = ({ children }) => {
  return (
    <div className="flows-page-grid">
      {children}
    </div>
  );
};

export default FlowsPageGrid;
