import { unprotectedGetRequest } from "./apiGenerics"

export const GetTestsByCustomerID = async (customer_id: string) => {
    console.log('Fetching tests for customer_id under GetTestsByCustomerID:', customer_id);
    const tests = await unprotectedGetRequest(
      `tests/getCustomerTests?customer_id=${customer_id}`
    )
    return tests
  }

  export const GetRelatedRecordObject = async (record_id: string) => {
    console.log('Fetching related record object for record_id:', record_id);
    const record = await unprotectedGetRequest(`records/getSharedRecord?record_id=${record_id}`);
    // console.log(record);
    return { json: record.json, data: record.data };
  };