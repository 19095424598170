import React, { useEffect, useState } from 'react';
import { FlowType, setCurrentFlow, flowsState } from '../../../app/slices/FlowsSlice';
import moment from 'moment';
import clock from '../../../Assets/clock.png'
import './FlowLeftSideBarItem.css';



interface FlowLeftSideBarItemProps {
  flow: FlowType;
  isPresented: boolean;
  handleFlowClick: (flow: FlowType) => void;
}
const FlowLeftSideBarItem: React.FC<FlowLeftSideBarItemProps> = ({ flow, isPresented, handleFlowClick }) => {

  return (
    <div
      key={flow.id}
      onClick={() => handleFlowClick(flow)}
      className={`flow-item ${isPresented
          ? 'flow-item-selected'
          : 'flow-item-unselected'} ${flow.is_pending
          ? 'flow-item-pending'
          : ''
        }`
      }
    >
      <div className="flow-name">{flow.name}</div>
      <div className="flow-item-date">
        Created at {moment(new Date(flow.created_at).toLocaleString()).format('DD/MM/YY HH:mm')}
      </div>
      <div className="flow-item-type">
        {flow.is_pending 
        ? 'Scenario In Prorgess...'
        : ""
      }
      {flow.is_pending &&
        <div className='clock-icon-container'>
          <img src={clock} alt='clock icon' className='clock-icon' />
        </div>
      }
      </div>
    </div>
  );
};

export default FlowLeftSideBarItem;