import React from 'react';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '../../../app/hooks';
import { flowsState } from '../../../app/slices/FlowsSlice';
import './FlowCodeBlock.css';
import { Buffer } from 'buffer';

const FlowCodeBlock: React.FC<{}> = () => {
  const { currentFlow } = useAppSelector(flowsState);

  const bufferData = (currentFlow?._steps as any)?.data;
  let stepsData = 'We couldn’t find a code for this test.';

  if (bufferData) {
    const buffer = Buffer.from(bufferData);
    stepsData = buffer.toString('utf-8');
  }

  let parsedData: {
    steps?: (string | string[])[];
    validations?: string[];
    assertions?: string[];
  } = {};

  try {
    parsedData = JSON.parse(stepsData);
  } catch (e) {
    // If parsing fails, parsedData remains empty and stepsData is a fallback
  }

  const { steps = [], validations = [], assertions = [] } = parsedData;

  // The input format: 
  // steps: [
  //   "Open the email client",
  //   ["Compose a new email","Enter recipient email address","Write a subject and body"],
  //   "Attach a file",
  //   "Click the send button"
  // ]

  // The first element of steps is considered the main goal.
  const mainGoal = steps.length > 0 ? steps[0] : 'No main goal found.';

  // The rest of the steps (beyond the first element) should be flattened
  // For example, steps might look like:
  // ["Open the email client", ["Compose a new email","Enter recipient email address","Write a subject and body"], "Attach a file", "Click the send button"]
  // After removing the first element: 
  // [["Compose a new email","Enter recipient email address","Write a subject and body"], "Attach a file", "Click the send button"]
  // Flatten that into a single array of strings.
  const otherSteps = steps.slice(1).flatMap((step) => (Array.isArray(step) ? step : [step]));

  return (
    <div className="flow-code-block-container">
      <div className="flow-code-header">
        <div className="flex items-center">
          <Bars3BottomLeftIcon className="h-8 w-8 mr-4 text-tuto-primary" />
          <h1 className="general-title">{currentFlow?.name}</h1>
        </div>
        <div className="pr-4"></div>
      </div>
      <div className="formatted-content">
        {mainGoal && <h2>Summary: {currentFlow?.description}</h2>}

        {otherSteps.length > 0 && (
          <div>
            <h3>User Steps:</h3>
            <ol>
              {otherSteps.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ol>
          </div>
        )}

        {validations.length > 0 && (
          <div>
            <h3>Validation Steps:</h3>
            <ol>
              {validations.map((val, index) => (
                <li key={index}>{val}</li>
              ))}
            </ol>
          </div>
        )}

        {assertions.length > 0 && (
          <div>
            <h3>Assertions:</h3>
            <ol>
              {assertions.map((assertion, index) => (
                <li key={index}>{assertion}</li>
              ))}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlowCodeBlock;