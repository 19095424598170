import { FC, Fragment } from 'react'
import MainTestView from './MainTestView'




const TestsRightSideBar: FC<{}> = () => {

  return (
    <Fragment>
        <>
      <div className="bg-gray-100 border rounded-xl flex-grow overflow-scroll mt-8">
        <MainTestView/>
      </div>
      </>
    </Fragment>
  );
}

export default TestsRightSideBar
