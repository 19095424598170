import React, { useEffect, useState } from 'react';
import CountUp, { CountUpProps } from 'react-countup';
import './Counter.css';

interface CounterProps extends CountUpProps {
  title: string;
}

const Counter: React.FC<CounterProps> = ({
  end,
  title,
  duration = 3.5,
  start = 0,
  delay = 0,
  decimals = 0,
  prefix = '',
  suffix = '',
  separator = '',
  className = 'counter',
}) => {
  const [circleLength, setCircleLength] = useState(0);

  useEffect(() => {
    setCircleLength(2 * Math.PI * 45); // 2 * PI * radius (radius is 45 here)
  }, []);

  return (
    <div className="number pr-5">
      <svg viewBox="0 0 100 100">
        <circle
          className="background-circle"
          strokeWidth="3"
          stroke="currentColor"
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
        />
        <circle
          className="foreground-circle"
          strokeWidth="3"
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
          style={{
            strokeDasharray: circleLength,
            strokeDashoffset: circleLength,
            animation: `countdown ${duration}s linear forwards`,
          }}
        />
      </svg>
      <div className='flex flex-col items-center justify-center'>
      <CountUp
        start={start}
        end={end}
        duration={duration}
        delay={delay}
        decimals={decimals}
        prefix={prefix}
        suffix={suffix}
        separator={separator}
        className="countup-text"
      />
      <span className="title-text-sm">{title}</span>
      </div>
    </div>
  );
};

export default Counter;
