import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import customerDomainsSlice from './slices/customerDomainsSlice';
import customerIntegrationsSlice from './slices/customerIntegrationsSlice';
import customerUsersSlice from './slices/customerUsersSlice';
import investigationsSlice from './slices/investigationsSlice';
import notificationsSlice from './slices/notificationsSlice';
import ProblemsSlice from './slices/ProblemsSlice';
import UiSlice from './slices/uiSlice';
import viewsSlice from './slices/viewsSlice';
import investigationParametersSlice from './slices/investigationParametersSlice';
import testsReducer from './slices/TestsSlice';
import flowsReducer from './slices/FlowsSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    customerUsers: customerUsersSlice,
    notifications: notificationsSlice,
    customerDomains: customerDomainsSlice,
    problems: ProblemsSlice,
    ui: UiSlice,
    customerIntegrations: customerIntegrationsSlice,
    views: viewsSlice,
    investigations: investigationsSlice,
    investigationParameters: investigationParametersSlice,
    tests: testsReducer,
    flows: flowsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
