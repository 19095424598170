import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { GetRelatedRecordObject, GetTestsByCustomerID } from '../apis/testsApi';

export interface TestType {
  id: string | number;
  created_at: string;
  related_record_id: string;
  related_record_object: any | null;
  related_record_duration: number | null;
  customer_id: string | number;
  is_from_record: boolean;
  name: string;
  _transcription: string | null;
  flow_id: string;
  is_deleted: boolean;
  is_pending: boolean;
}

interface TestsState {
  tests: TestType[];
  currentTest: TestType | null;
  initLoading: boolean;
  recordLoading: boolean;
  error: string | null;
}

const initialState: TestsState = {
  tests: [],
  currentTest: null,
  initLoading: false,
  recordLoading: false,
  error: null,
};

export const fetchTests = createAsyncThunk(
  'tests/fetchTests',
  async (customer_id: any) => {
    const fetchedTests = await GetTestsByCustomerID(customer_id);
    // console.log(fetchTests)
    return fetchedTests.data as TestType[];
  }
);

export const fetchRelatedRecordObject = createAsyncThunk(
  'tests/fetchRelatedRecordObject',
  async ({ testId, record_id }: { testId: string | number; record_id: string }, { getState }) => {
    const state = getState() as RootState;
    const test = state.tests.tests.find(test => test.id === testId);
  
    if (test?.related_record_object) {
      //alert('Already fetched')
      return { testId, related_record_object: test.related_record_object };
    }

    const relatedRecordObject = await GetRelatedRecordObject(record_id);
    return { testId, related_record_object: relatedRecordObject };
  }
);

const testsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    setCurrentTest(state, action: PayloadAction<TestType | null>) {
      state.currentTest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTests.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchTests.fulfilled, (state, action: PayloadAction<TestType[]>) => {
        state.initLoading = false;
        state.tests = action.payload;
      })
      .addCase(fetchTests.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(fetchRelatedRecordObject.pending, (state) => {
        state.recordLoading = true;
      })
      .addCase(fetchRelatedRecordObject.fulfilled, (state, action: PayloadAction<{ testId: string | number; related_record_object: any }>) => {
        state.recordLoading = false;
        const { testId, related_record_object } = action.payload;
        const test = state.tests.find(test => test.id === testId);
        if (test) {
          test.related_record_object = related_record_object;
        }
        if (state.currentTest && state.currentTest.id === testId) {
          state.currentTest.related_record_object = related_record_object;
        }
      })
      .addCase(fetchRelatedRecordObject.rejected, (state, action) => {
        state.recordLoading = false;
        state.error = action.error.message ?? null;
      });
  },
});

export const { setCurrentTest } = testsSlice.actions;
export const testsState = (state: RootState) => state.tests;
export default testsSlice.reducer;
