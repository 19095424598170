/* eslint no-unneeded-ternary: 0 */
import { FC } from 'react'
import '../../../App.css'
import { is } from 'cypress/types/bluebird'

interface IButtonState {
  loading: boolean
  text?: string
  inverted: boolean
  Icon?: any
  disabled: boolean
  clickFunction?: any
  type: 'button' | 'submit'
  withIcon: boolean
  spacialClass?: string
  textColor?: string
  children?: React.ReactNode
  dataCy?: string
  bgColor?: string
  id?: string
  isFullWidth?: boolean
  textSize?: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Button: FC<IButtonState> = ({
  type,
  disabled,
  text,
  Icon,
  loading,
  withIcon,
  spacialClass,
  clickFunction,
  inverted,
  textColor,
  dataCy,
  children,
  bgColor,
  id,
  isFullWidth,
  textSize
}) => {
  return (
    <button
      id={id ? id : ''}
      disabled={disabled}
      type={type}
      {...(dataCy ? { 'data-cy': `${dataCy}` } : {})}
      className={classNames(
        !inverted
          ? `${spacialClass} text-white ${
              textColor ? textColor : 'text-white'
            } ${
              bgColor ? bgColor : 'bg-tuto-purple'
            } hover:bg-tuto-secondary hover:text-tuto-purple focus:outline-none`
          : `${spacialClass} ${
              textColor ? textColor : 'bg-tuto-secondary text-tuto-purple'
            } ${
              bgColor ? bgColor : 'bg-tuto-secondary'
            } hover:bg-tuto-purple shadow-none hover:text-white focus:outline-none`,
        `inline-flex items-center ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } justify-around px-4 py-2 border border-transparent purple-shadow font-medium rounded-md transform transition duration-300`,
        isFullWidth && 'w-full',
        textSize && `text-${textSize}`
      )}
      onClick={clickFunction}
    >
      {withIcon && (
        <Icon className={`h-5 w-5 ${text ? 'mr-1' : ''}`} aria-hidden="true" />
      )}
      {loading && (
        <span
          className={`animate-spin h-5 w-5 mr-3 border-t-2 ${
            inverted ? 'border-purple-600' : 'border-white'
          } rounded-full`}
        ></span>
      )}
      {text}
    </button>
  )
}

export default Button
