import React, { FC, useEffect, useState } from 'react';
import Player from '../../utils/player/Player';
import { fetchRelatedRecordObject,fetchRelatedTests, flowsState } from '../../../app/slices/FlowsSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import './MainFlowView.css'; // Assuming you have a CSS file for custom styles

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const loaderStyle = {
  width: '120px', // Medium size width
  height: '100px',
  '--_g': 'no-repeat radial-gradient(circle closest-side,#900EF2 90%,#0000)',
  background: `
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%
  `,
  backgroundSize: 'calc(100%/3) 100%',
  animation: 'd7 1s infinite ease-in-out',
};

const MainFlowView: FC = () => {
  const dispatch = useAppDispatch();
  const { currentFlow, recordLoading } = useAppSelector(flowsState);
  const [recordSession, setRecordSession] = useState(null);
  const [recordData, setRecordData] = useState<any>({});

  useEffect(() => {
    if (currentFlow) {
      setRecordSession(null);
      setRecordData(null);
      console.log("new flow_id:", currentFlow?.id)
      dispatch(fetchRelatedTests({ flow_id: currentFlow.id, customer_id: currentFlow.customer_id }));
      
      if  (currentFlow.related_record_id) {
        dispatch(fetchRelatedRecordObject({ flowId: currentFlow.id, record_id: currentFlow.related_record_id }));
      }
    }
  }, [currentFlow?.id]);

  useEffect(() => {
    console.log("recordLoading")
    if (!recordLoading && currentFlow?.related_record_object) {
      setRecordSession(JSON.parse((currentFlow.related_record_object as any).json));
      setRecordData((currentFlow.related_record_object as any).data);
    }
  }, [recordLoading]);

  return (
    <div className="main-flow-view">
      <div className="related-session-container">
        {/* <h1 className="general-title m-5">{'Related Session'}</h1> */}
        <div>
          {recordLoading ? (
            <div style={loaderContainerStyle}>
              <div className="loader" style={loaderStyle}></div>
            </div>
          ) : (
            recordSession ? (
              <Player
                audioSource={recordData.path}
                session={recordSession}
              />
            ) : (
              <div style={loaderContainerStyle}>
                <span>Can't find the related session</span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default MainFlowView;
