import { unprotectedGetRequest, unprotectedPostRequest } from "./apiGenerics"

export const GetFlowsByCustomerID = async (customer_id: string) => {
    const flows = await unprotectedGetRequest(
      `flows/getCustomerFlows?customer_id=${customer_id}`
    )
    return flows
  }

  export const GetRelatedRecordObject = async (record_id: string) => {
    const record = await unprotectedGetRequest(`records/getSharedRecord?record_id=${record_id}`);
    return { json: record.json, data: record.data };
  };

  export const GetTestsByFlowId = async (flow_id: string, customer_id: string) => {
    const tests = await unprotectedGetRequest(
      `tests/getTestsByFlowId?flow_id=${flow_id}&customer_id=${customer_id}`
    )
    return tests
  }

export const CreateFlow = async (FlowData: any) => {
  const newFlow = await unprotectedPostRequest(
    `flows/createFlow`, 
    {...FlowData}
  )
  return newFlow
}