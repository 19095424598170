import { LinkIcon } from '@heroicons/react/24/outline'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../../utils/elements/Button'
import config from '../../../../../config/config'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { authState } from '../../../../../app/slices/authSlice'
import Knowledge from '../../../Knowledge/Knowledge'
import InstructionsForm from '../../../Knowledge/InstructionsForm'
import { addNewNotification } from '../../../../../app/slices/notificationsSlice'
import { v4 as uuidv4 } from 'uuid'

const DomainsEmpty = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(authState)
  const [instructionsFormOpen, setInstructionsFormOpen] = useState(false)
  const [noDomainText, setNoDomainText] = useState(
    'I installed the code but still cant see my domain here'
  )

  const sendNoDomainMail = async () => {
    const body = {
      user_id: user.id,
      customer_id: user.customer_id,
      email: user.email
    }

    const res = await fetch(`${config.api}/api/v1/users/sendNoDomainMail`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const paresedRes = await res.json()
    if (paresedRes.success) {
      setNoDomainText('We Notified our team and we will contact you soon!')
      dispatch(
        addNewNotification({
          text: `We Notified our team and we will contact you soon!`,
          id: uuidv4(),
          title: 'We Got Your Request',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
    }
  }
  return (
    <div>
      <div className="mb-16">
        <Knowledge
          title={'Installation Instructions'}
          isOpen={instructionsFormOpen}
          setSlideOpen={setInstructionsFormOpen}
        >
          <InstructionsForm setSlideOpen={setInstructionsFormOpen}/>
        </Knowledge>
        <h1 className="general-title">Your Domains</h1>
        <p className="text-xl text-gray-500">
          We currently didnt find any domains which our code is installed on
        </p>
      </div>
      <div className="flex">
        <div className="p-6 max-w-2xl">
          <Link to="settings/installation">
            <div className="flex items-center p-10 border rounded-2xl text-gray-500 mb-6 hover:border-tuto-primary">
              <LinkIcon className="h-20 w-20 mr-5" />
              <div>
                Go to the installation page to see how to install and embed tuto
                on tour site
              </div>
            </div>
          </Link>
          <div
            onClick={sendNoDomainMail}
            className="flex items-center p-10 border rounded-2xl text-gray-500 hover:border-tuto-primary cursor-pointer"
          >
            <ExclamationCircleIcon className="h-20 w-20 mr-5" />
            <div>{noDomainText}</div>
          </div>
        </div>
        <div className="p-6">
          <h1 className="text-3xl text-light-black font-semibold mb-4">
            Installation steps
          </h1>
          <ol>
            <li>1. Please install the code on any web environment </li>
            <li>2. Record will Identify your domain and notify you.</li>
            <li>3. Approve your domain under settings/domains.</li>
            <li>
              4. Share your unique link with your users to enable recording
            </li>
            <li> 5. See the record on your dashboard</li>
          </ol>
          <div className="mt-4">
            <div className="mb-4">
              <Button
                inverted={true}
                loading={false}
                disabled={false}
                text="Send installation instructions"
                type="button"
                withIcon={false}
                spacialClass="text-xs mr-2"
                clickFunction={() => setInstructionsFormOpen(true)}
              />
            </div>
            <div className="mb-4">
              <Link to='/settings/docs'>
                <Button
                  inverted={true}
                  loading={false}
                  disabled={false}
                  text="Installation guide"
                  type="button"
                  withIcon={false}
                  spacialClass="text-xs mr-2"
                />
              </Link>
            </div>
            <div>
              <Link to='/settings/extension'>
                <Button
                  inverted={true}
                  loading={false}
                  disabled={false}
                  text="Try Record Extension"
                  type="button"
                  withIcon={false}
                  spacialClass="text-xs mr-2"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DomainsEmpty
