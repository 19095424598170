import { FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { testsState } from '../../../app/slices/TestsSlice';
import './TestsFeedback.css';
import { unprotectedPostRequest } from '../../../app/apis/apiGenerics';
import { addNewNotification } from '../../../app/slices/notificationsSlice'

const TestsFeedback: FC = () => {
  const { currentTest } = useAppSelector(testsState);
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    if (!currentTest) {
      console.error("currentTest is null or undefined!");
    }
  }, [currentTest]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value) 
  };

  const handleSendFeedback = async () => {
    if (!feedback.trim()) return;
    setIsLoading(true)
    try {
      const response = await unprotectedPostRequest(`tests/postFeedback`, {
        testId: currentTest?.id,
        customerId: currentTest?.customer_id,
        feedback: feedback
      })
      console.log('Feedback sent successfully:', response);

      dispatch(
        addNewNotification({
          text: `Great Success`,
          title: 'Feedback sent to Record team!',
          type: 'SUCCESS',
          timer: true,
          button: false
        })
      )
      setFeedback('');
    } catch (error) {
      console.error('Error sending feedback:', error);
      dispatch(
        addNewNotification({
          text: `Please try to resubmit`,
          title: 'Error sending feedback:',
          type: 'WARN',
          timer: true,
          button: false
        })
      )
      setFeedback('');
    } finally {
      setIsLoading(false)
    }
  };

  return (
      <div className="feedback-container">
        <textarea
          className='feedback-input'
          value={feedback}
          onChange={handleChange}
          placeholder={`Tell us what you think`}
        />
        <button 
          className={(feedback.trim() === "") ? 'feedbackDisabled' : 'feedback-button'}
          onClick={handleSendFeedback}
          disabled={isLoading&& (feedback.trim() === "")}
         >
          {isLoading ? 'Sending...' : 'Send Feedback'}
        </button>
      </div>
  );
}

export default TestsFeedback;

// function dispatch(arg0: { payload: any; type: string; }) {
//   throw new Error('Function not implemented.');
// }
