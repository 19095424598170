import React, { FC } from 'react'
import './StickyFlows.css'

interface StickyLeftFlowsProps {
  children: React.ReactNode
}

const StickyLeftFlows:FC<StickyLeftFlowsProps> = ({children}) => {
  return (
    <div className={`sticky-left-flows`}>{children}</div>
  )
}

export default StickyLeftFlows